import React from 'react'
import UploadModal from '../../components/uploadModal'
import Gallery from '../../components/imageGallery/gallery'
import { useSelector } from 'react-redux'
import { useCarromm } from '../../context/carromm'

const Home = () => {
  const previewImages = useSelector(state => state.carromm.previewImages);
  const carrom = useCarromm();

  const handleNext = () => {
    carrom.setTabs(carrom.tabs.map((t) => ({ ...t, active: t.id === 'backgrounds' })));
  };
  return (
    <>
      <h2
        className="upload-modal-title">
        picDesigner Demo
      </h2>
      <p
        className="upload-modal-description">
        Professionelle Aufnahmen mit wenigen Klicks
      </p>
      <UploadModal
        handleClick={handleNext} />
      {previewImages &&
        <Gallery
          items={previewImages}
          title={'Bilder hochladen'} />}
    </>
  )
}

export default Home