import React from 'react'
import SelectedCarBlock from '../../components/selectedCarBlock';
import { rawDataNumberPlates } from '../../constants/rowData.constants';
import { useSelector, useDispatch } from 'react-redux';
import { setNumberPlate } from '../../redux/slices/carrommSlice';
import { useCarromm } from '../../context/carromm';

const NumberPlate = () => {
  const { number_plate } = useSelector(state => state.carromm);

  const dispatch = useDispatch();
  const carrom = useCarromm();

  const handleNext = () => {
    carrom.setTabs(carrom.tabs.map((t) => ({ ...t, active: t.id === 'show-result' })));
  };
  return (
    <>
      <h2 className="upload-modal-title">picDesigner Demo</h2>
      <p className="upload-modal-description">Professionelle Aufnahmen mit wenigen Klicks</p>
      <div className="select-background-block">
        <div className="bg-list">
          <ul>
            {rawDataNumberPlates &&
              Array.isArray(rawDataNumberPlates) &&
              rawDataNumberPlates?.map((item, index) => (
                <li
                  key={item?.id || index}
                  className={item.isSelected || number_plate?.id === item?.id ? "selected-bg" : ''}
                  onClick={() => dispatch(setNumberPlate(item))}>
                  {item?.thumbnail ? <img
                    src={item?.thumbnail}
                    alt={item?.thumbnail} /> :
                    <div className='default-list-item'>
                      Keine Maske
                    </div>
                  }

                </li>
              ))}
          </ul>
        </div>
        <SelectedCarBlock
          handleNext={handleNext} />
      </div>
    </>
  )
}

export default NumberPlate