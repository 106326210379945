import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { setLogin } from '../../redux/slices/carrommSlice';

function Login() {
  const dispatch = useDispatch();

  const handleOnsubmit = (e) => {
    e.preventDefault();
    const passcode = process.env.REACT_APP_PASSCODE || "carromm@123";
    const password = e.target.passcode.value;
    if (password !== passcode) {
      toast.error("Ungültiges Passwort");
      return false;
    }
    localStorage.setItem('user', true);
    dispatch(setLogin(password === passcode))
  }
  return (
    <div className="container-fluid">
      <div className="row justify-content-center align-items-center vh-90">
        <div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3">
          <center>
            <img className="mb-4" alt="" src="./assets/image/logo.png" />
          </center>
          <form action="" onSubmit={handleOnsubmit}>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1" className='mb-1'>Kennwort</label>
              <input type="password" className="form-control" name="passcode" id="exampleInputPassword1" placeholder="Kennwort" />
            </div>
            <div className="form-group mt-2">
              <button className="btn btn-info btn-block w-100 text-light">Anmelden</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;