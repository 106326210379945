import React from 'react'

const ImgBox = ({ imageUrl }) => {
  return (
    <div className="car-img-box">
      <img src={imageUrl} alt={imageUrl} />
    </div>
  )
}

export default ImgBox