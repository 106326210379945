import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { getImage, setInputUrl, setOutputUrl, setPreviewImages, setStatus, uploadImages } from '../../redux/slices/carrommSlice';
import { useCarromm } from '../../context/carromm';
import { CLOUD_URL, defaultValues } from '../../constants/common.constants';
import axios from "axios";

const chunkSize = 10 * 1024;
const Result = () => {
  const { previewImages, bg_id, window_refinement, number_plate, input_url, loading, output_url, status, sku_id } = useSelector(state => state.carromm);
  const dispatch = useDispatch();
  const carrom = useCarromm();
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [currentProgress, setCurrentProgress] = useState({ stage: 0, progressTitle: "" });
  const [isDownloading, setIsDownloading] = useState(false);

  const processImage = (input) => {
    setCurrentProgress({ stage: 2, progressTitle: "Senden an API" })
    if (input.type === 'image' && !carrom.input) {
      toast.error("Bitte wählen Sie zuerst eine Datei aus");
      setCurrentProgress({ stage: -1, progressTitle: "Fehler" })
      return;
    } else if (input.type === 'url' && !input.value) {
      setCurrentProgress({ stage: -1, progressTitle: "Fehler" })
      toast.error("Bild in verfügbar");
      return;
    }
    const windowRefinement = {
      'TRANSPARENCY': Number(window_refinement?.TRANSPARENCY || defaultValues?.window_refinement?.TRANSPARENCY),
      'TINT_COLOR': window_refinement?.TINT_COLOR || defaultValues?.window_refinement?.TINT_COLOR,
      'TONE_ON_TONE': window_refinement?.TONE_ON_TONE ? 'True' : 'False',
      'ADD_GLARE': window_refinement?.ADD_GLARE ? 'True' : 'False',
    }
    let formdata = new FormData();
    formdata.append("bg_id", bg_id);
    formdata.append("window_refinement", JSON.stringify(windowRefinement));
    formdata.append("interior_regeneration", "1");
    // formdata.append("number_plate", number_plate?.thumbnail || defaultValues?.number_plate);
    formdata.append("number_plate", number_plate?.thumbnail || "");
    formdata.append("extension", "");
    input.type === 'image' ?
      formdata.append("image_file", carrom.input) :
      formdata.append("image_url", input.value);
    dispatch(uploadImages(formdata))
      .unwrap()
      .then(
        (res) => {
          res.status === "accepted" &&
            dispatch(getImage(res.sku_id))
          dispatch(setPreviewImages(null))
          setCurrentProgress({ stage: 3, progressTitle: "Warten auf API-Antwort" })
        }
      )
      .catch((error) =>
        toast.error(`Bild-Upload fehlgeschlagen: ${error}`)
      );
  }
  const handleUpload = () => {
    if (!carrom.input) {
      toast.error("Bitte wählen Sie zuerst eine Datei aus");
      return;
    }
    if (carrom.input?.size > 5242880) {
      // setFiles([carrom.input]);
      return false
    } else {
      processImage({ type: 'image' })
    }
  }

  useEffect(() => {
    let timer;
    if (status)
      if (status === 'processing' || status === "accepted") {
        timer = setInterval(() => {
          dispatch(getImage(sku_id))
        }, 5000);
      } else {
        setCurrentProgress({ stage: 4, progressTitle: "Erledigt" })
        setTimeout(() => {
          setFiles([]);
        }, 5000)
      }
    return () => {
      clearInterval(timer);
    }
  }, [sku_id, status])

  function readAndUploadCurrentChunk() {
    const reader = new FileReader();
    const file = files[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadChunk(readerEvent) {
    const file = files[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set('name', file.name);
    params.set('size', file.size);
    params.set('currentChunkIndex', currentChunkIndex);
    params.set('totalChunks', Math.ceil(file.size / chunkSize));
    const headers = { 'Content-Type': 'application/octet-stream' };
    const url = `${CLOUD_URL}/upload?${params.toString()}`;
    axios.post(url, data, { headers })
      .then(response => {
        const file = files[currentFileIndex];
        const filesize = files[currentFileIndex].size;
        const chunks = Math.ceil(filesize / chunkSize) - 1;
        const isLastChunk = currentChunkIndex === chunks;
        if (isLastChunk) {
          file.finalFilename = response.data.finalFilename;
          setLastUploadedFileIndex(currentFileIndex);
          setCurrentChunkIndex(null);
          processImage({ type: 'url', value: `${CLOUD_URL}/uploads/${response.data.finalFilename}` })
        } else {
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === files.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (files.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
        setCurrentProgress({ stage: 1, progressTitle: "Hochladen..." });
      }
    }
  }, [files.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex]);


  const handleDownload = async () => {
    const fileName = output_url.split('/').pop();
    if (!fileName) {
      toast.error('Download file not found');
      return;
    }
    setIsDownloading(true);
    try {
      // const response = await axios.get(`/api/${fileName}`, {
      //   responseType: 'blob',
      // });
      const response = await axios.get(output_url, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
      setIsDownloading(false);
    } catch (error) {
      toast.error('Error downloading file:', error.message);
      setIsDownloading(false);
    }
  }
  const handleReset = () => {
    dispatch(setOutputUrl(""))
    dispatch(setInputUrl(null))
    dispatch(setPreviewImages(null))
    dispatch(setStatus(""))
    carrom.setInput(null);
    carrom.setTabs(carrom.tabs.map((t) => ({ ...t, active: t.id === 'home' })));
    window.location.reload();
  };
  return (
    <>
      <h2 className="upload-modal-title">picDesigner Demo</h2>
      <p className="upload-modal-description">Professionelle Aufnahmen mit wenigen Klicks</p>
      <div className="before-after-block">
        <div className="before-process-box">
          <h2 className="title-20">Original</h2>
          <div className="img-holder">
            <img
              src={
                previewImages &&
                  previewImages?.[0] &&
                  previewImages?.[0]?.url ?
                  previewImages?.[0]?.url :
                  input_url
              }
              alt=''
            />
          </div>
        </div>
        <div className="after-process-box">
          <h2 className="title-20">Ergebnis</h2>
          <div className="img-holder">
            {!(loading || status === 'processing' || status === "accepted") ? <>
              {output_url ?
                <img
                  src={output_url}
                  alt={output_url} /> :
                <img
                  src="https://carromm.s3.amazonaws.com/raw/number_plate.png"
                  alt="https://carromm.s3.amazonaws.com/raw/number_plate.png" />
              }
            </> :
              <div className="img_loader">
                <video autoPlay loop muted playsInline className="w-100">
                  <source src="./assets/video/loader.mp4" type="video/mp4" />
                </video>
              </div>
            }

          </div>
        </div>
      </div>
      <div className="process-btn-wrap w-100">
        {status === '' && carrom.input ? <button
          className="btn btn-process"
          onClick={handleUpload}>
          <img
            src="./assets/image/processing.png"
            alt=""
            className=''
            style={{ width: "32px" }} /> {' '}
          Jetzt optimieren
        </button> :
          <button
            className="btn btn-process"
            onClick={handleReset}>
            <img
              src="./assets/image/icon/reset.svg"
              alt=""
              className=''
              style={{ width: "32px", filter: 'invert(1)' }} /> {' '}
            Versuchen Sie ein anderes Bild
          </button>
        }

        <button
          className={`btn btn-process ms-2 ${output_url ? '' : 'opacity-25'}`}
          disabled={!output_url}
          onClick={handleDownload}>
          {isDownloading ? <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Herunterladen...
          </> :
            <>
              <img
                src="./assets/image/icon/download.svg"
                alt=""
                className=''
                style={output_url ? { width: "32px", filter: 'invert(1)' } : { width: "32px" }} /> {' '}
              herunterladen
            </>
          }
        </button>
      </div>

      <div className="files">
        {files.map((file, fileIndex) => {
          let progress = 0;
          if (file.finalFilename) {
            switch (currentProgress.stage) {
              case 2: progress = 70; break;
              case 3: progress = 80; break;
              case 4: progress = 100; break;
              default:
                progress = 100;
                break;
            }
          } else {
            const uploading = fileIndex === currentFileIndex;
            const chunks = Math.ceil(file.size / chunkSize);
            if (uploading) {
              progress = Math.round(currentChunkIndex / chunks * 60);
            } else {
              progress = 0;
            }
          }
          return (
            <a
              className="file"
              target="_blank"
              href={`${CLOUD_URL}/uploads/${file.finalFilename}`}
              key={fileIndex + file?.lastModifiedDate}>
              <div className="name">{file.name}</div>
              <div className={"progress " + (progress === 100 ? 'done' : '')}
                style={{ width: progress + '%' }}></div>
              <div className="progressText">{currentProgress.progressTitle} {progress}%</div>
            </a>
          );
        })}
      </div>
    </>
  )
}

export default Result