import React from 'react'

const DefaultLayout = ({ children, ...rest }) => {
  return (
    <div className="select-vehicle-box">
      <div className="car-tab-block">
        <div className='container-fluid d-flex align-items-start bg-white'>
          {React.cloneElement(children, { rest })}
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout