import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useCarromm } from '../../context/carromm';

const tabState = (t, i, arr) => {
  console.log("status update use Effect", t, i, arr)
  console.log("status content: ", t?.content, "status content name: ", t.content?.name, "status content compare with Backgrounds: ", t.content.name === 'Backgrounds', "status content arr[0]: ", !!arr[0])
  if (i === 0) {
    return false;
  }
  switch (i) {
    case 0:
      return false;
    case 1:
      return !(t?.content && !!arr[0]);
    case 2:
      return !(t?.content && !!arr[0] && !!arr[1]);
    case 3:
      return !(t?.content && !!arr[0] && !!arr[1] && !!arr[2]);
    case 4:
      return !(t?.content && !!arr[0] && !!arr[1] && !!arr[2] && !!arr[3]);
    default:
      break;
  }
  return true
}
const SideTabs = ({ contents }) => {
  const { previewImages, bg_id, window_refinement, number_plate } = useSelector(state => state.carromm);
  const carrom = useCarromm();

  const selectTabHandler = (tab) => {
    carrom.setTabs(carrom.tabs.map((t) => ({ ...t, active: t.id === tab.id })));
  };

  useEffect(() => {
    carrom.setTabs(carrom.tabs.map((t, i) => ({ ...t, content: contents[i], isDisable: i !== 0 })));
  }, [contents?.length]);

  useEffect(() => {
    if (carrom.tabs && carrom.tabs.length > 0 && carrom.tabs[0].content && previewImages) {
      carrom.setTabs(carrom.tabs.map((t, i) => ({
        ...t,
        isDisable: tabState(t, i, [previewImages, bg_id, window_refinement?.TRANSPARENCY, number_plate])
      })));
    }
  }, [previewImages, bg_id, window_refinement?.TRANSPARENCY])

  const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };
  return (
    <>
      {/* Tabs menus  */}
      <ul className="nav nav-pills nav-pills" id="pills-tab" role="tablist">
        <li className="logo-item">
          <img
            src="./assets/image/logo.png"
            alt='logo' />
        </li>
        {carrom.tabs.map((tab, index) => (
          <li
            key={tab?.id || index}
            className={`nav-item ${tab?.isDisable ? 'disabled' : ''}`}
            role="presentation"
            onClick={() => selectTabHandler(tab)}>
            <center
              className={`nav-link fw-semibold ${tab?.name !== 'Result' ? 'position-relative' : ''}${tab?.active ? ' active' : ''}`}
              data-bs-toggle="pill"
              data-bs-target={`#pills-${tab?.id}`}
              role="tab"
              aria-controls={`pills-${tab?.id}`}
              aria-selected={tab?.active}>
              {tab?.icon &&
                <img
                  src={tab?.icon}
                  alt={tab?.icon} />}
              <span>
                {tab?.title}
              </span>
            </center>
          </li>
        ))}
        <li 
        className="nav-item" 
        onClick={()=> logout()}>
          <center
            className={`nav-link fw-semibold position-relative`}
          >
            {/* <img
              src='./assets/image/icon/logout.svg'
              alt='./assets/image/icon/logout.svg' 
              className=''/> */}
            Abmeldung
          </center>
        </li>
      </ul>
      {/* Tabs Contents */}
      <div
        id="pills-tabContent"
        className="tab-content w-100"
      >
        {carrom.tabs.map((tab, index) => (
          <div
            key={`contents-${tab?.id || index}`}
            className={`tab-pane fade${tab?.active ? ' show active' : ''}`}
            id={`pills-${tab?.id}`}
            role="tabpanel"
            aria-labelledby={`pills-${tab?.id}-tab`}
          >
            {tab.content && <tab.content />}
          </div>
        ))}
      </div>
    </>
  )
}

export default SideTabs