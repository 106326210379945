import DefaultLayout from './components/layouts/default';
import SideTabs from './components/sideTab';
import Home from './pages/home';
import Backgrounds from './pages/background';
import Windows from './pages/windows';
import NumberPlate from './pages/numberPlate';
import Result from './pages/result';
import './Globle.css';
import './customGloble.css';
import Login from './pages/login/Login';
import { useSelector } from 'react-redux';
import React from 'react';

function App() {
  const contents = [Home, Backgrounds, Windows, NumberPlate, Result];
  const isLogin = useSelector(state => state.carromm.isLogin);

  return (
    <DefaultLayout>
      {
        isLogin ?
          <SideTabs contents={contents} /> :
          <Login />
      }

    </DefaultLayout>
  );
}

export default App;
