import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../services/ApiService';
import { toast } from "react-toastify";

/*************
 * /automobile/background/replace
 * Upload image to server
 * *************/
export const uploadImages = createAsyncThunk(
  "user/uploadImages",
  async (bodyContent) => {
    const response = await ApiService.post("/automobile/background/replace", bodyContent);
    const data = await response.data;
    return data;
  }
);

/*************
 * /automobile/background/replace
 * Get image to server
 * *************/
export const getImage = createAsyncThunk(
  "user/getImage",
  async (skuId) => {
    const response = await ApiService.get(`/automobile/background/replace?sku_id=${skuId}`);
    const data = await response.data;
    return data;
  }
);

const initialState = {
  loading: false,
  error: null,
  isLogin: localStorage.getItem('user') || false,
  inputImages: null,
  previewImages: null,
  bg_id: null,
  window_refinement: { 'TRANSPARENCY': null, 'TINT_COLOR': "(166, 205, 203)", 'TONE_ON_TONE': false, 'ADD_GLARE': true },
  interior_regeneration: 1,
  number_plate: null,
  extension: "",
  resData: null,
  sku_id: null, 
  model_version: "1.0.0",
  input_url: null,
  output_url: "",
  status: "",
}
export const carrommSlice = createSlice({
  name: 'carromm',
  initialState: initialState,
  reducers: {
    setInputImages: (state, action) => {
      state.output_url = "";
    },
    setPreviewImages: (state, action) => {
      state.previewImages = action.payload;
    },
    resetSkuId: (state, action) => {
      state.sku_id = null;
    },
    setBG: (state, action) => {
      state.bg_id = action.payload;
    },
    setWindowRefinementTransparency: (state, action) => {
      state.window_refinement.TRANSPARENCY = action.payload;
    },
    toggleGlare: (state, action) => {
      state.window_refinement.ADD_GLARE = action.payload;
    },
    setNumberPlate: (state, action) => {
      state.number_plate = action.payload;
    },
    setLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setInputUrl: (state, action) => {
      state.input_url = action.payload;
    },
    setOutputUrl: (state, action) => {
      state.output_url = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadImages.pending, (state, payload) => {
      state.loading = true;
    })
    builder.addCase(uploadImages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ? action.payload.errorMessage : action?.error?.message;
      toast.error(action.payload ? action.payload.errorMessage : action?.error?.message);
    })
    builder.addCase(uploadImages.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.status === "accepted") {
        state.sku_id = action?.payload?.data?.sku_id;
      } else {
        toast.error("Server-Fehler: Bild nicht auf Server hochgeladen mit Status" + action?.payload?.status + ".");
      }
    })

    builder.addCase(getImage.pending, (state, payload) => {
      state.loading = true;
    })
    builder.addCase(getImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ? action.payload.errorMessage : action?.error?.message;
      toast.error(action.payload ? action.payload.errorMessage : action?.error?.message);
    })
    builder.addCase(getImage.fulfilled, (state, action) => {
      state.loading = false;
      const { status, sku_id, input_url, output_url, model_version } = action?.payload;
      state.sku_id = sku_id;
      state.input_url = input_url;
      state.output_url = output_url;
      state.status = status;
      state.model_version = model_version;
      state.resData = action?.payload;
      switch (status) {
        case 'processing':
        case 'accepted':
          toast.info("Bild wird vom Server verarbeitet.");
          break;
        case 'processed':
          toast.success("Bild wird vom Server verarbeitet.");
          break;
        case 'processing_failed':
          toast.error("Server-Fehler: Bild wird vom Server nicht verarbeitet mit Status " + status + ".");
          break;
        default:
          toast.error("Server-Fehler: Unbekannter Status " + status + ".");
          break;
      }
    })
  },
})
export const {
  setInputImages,
  setPreviewImages,
  resetSkuId,
  setBG,
  setWindowRefinementTransparency,
  toggleGlare,
  setNumberPlate,
  setLogin,
  setInputUrl, 
  setOutputUrl,
  setStatus,
} = carrommSlice.actions;
export default carrommSlice.reducer;