import React from 'react'
import ImgBox from './imgBox';

const Gallery = ({ items, title }) => {
  const handleImageClick = (image) => {
    console.log("Click gallery element")
  }
  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <h2 className="upload-modal-title mb-3">{title}</h2>
      </div>
      {items.map((item, index) => (
        <div
          key={item?.id || index}
          className="col-sm-6 col-md-6 col-lg-4 col-xl-3"
          onClick={() => handleImageClick(item)}>
          <ImgBox imageUrl={item?.url} />
        </div>
      ))}
    </div>
  )
}

export default Gallery