import { PUBLIC_URL } from "./common.constants";

export const galleryImages = [
  {
    id: 1,
    url: './assets/image/1.jpg',
    title: 'Image 1',
    description: 'Image 1 description',
    category: 'Category 1',
  },
  {
    id: 2,
    url: './assets/image/2.jpg',
    title: 'Image 2',
    description: 'Image 2 description',
    category: 'Category 1',
  },
  {
    id: 3,
    url: './assets/image/3.jpg',
    title: 'Image 3',
    description: 'Image 3 description',
    category: 'Category 1',
  },
  {
    id: 4,
    url: './assets/image/4.jpg',
    title: 'Image 4',
    description: 'Image 4 description',
    category: 'Category 1',
  },
  {
    id: 5,
    url: './assets/image/5.jpg',
    title: 'Image 5',
    description: 'Image 5 description',
    category: 'Category 1',
  },

  {
    id: 6,
    url: './assets/image/1.jpg',
    title: 'Image 1',
    description: 'Image 1 description',
    category: 'Category 1',
  },
  {
    id: 7,
    url: './assets/image/5.jpg',
    title: 'Image 5',
    description: 'Image 5 description',
    category: 'Category 1',
  },
  {
    id: 8,
    url: './assets/image/1.jpg',
    title: 'Image 1',
    description: 'Image 1 description',
    category: 'Category 1',
  },
  {
    id: 9,
    url: './assets/image/3.jpg',
    title: 'Image 3',
    description: 'Image 3 description',
    category: 'Category 1',
  },
  {
    id: 10,
    url: './assets/image/4.jpg',
    title: 'Image 4',
    description: 'Image 4 description',
    category: 'Category 1',
  },
  {
    id: 11,
    url: './assets/image/5.jpg',
    title: 'Image 5',
    description: 'Image 5 description',
    category: 'Category 1',
  },

  {
    id: 12,
    url: './assets/image/2.jpg',
    title: 'Image 2',
    description: 'Image 2 description',
    category: 'Category 1',
  },
  {
    id: 13,
    url: './assets/image/1.jpg',
    title: 'Image 1',
    description: 'Image 1 description',
    category: 'Category 1',
  },
  {
    id: 14,
    url: './assets/image/2.jpg',
    title: 'Image 2',
    description: 'Image 2 description',
    category: 'Category 1',
  },
  {
    id: 15,
    url: './assets/image/3.jpg',
    title: 'Image 3',
    description: 'Image 3 description',
    category: 'Category 1',
  },
  {
    id: 16,
    url: './assets/image/4.jpg',
    title: 'Image 4',
    description: 'Image 4 description',
    category: 'Category 1',
  },
];

export const bgImages = [
  {
    id: 1,
    url: './assets/image/1.jpg',
    title: 'Image 1',
    description: 'Image 1 description',
    tintIntensity: 75,
    glore: true,
    category: {
      background: "Ganage1",
      window: "0.3",
      numberPlate: "White Mask"
    },
    isSelected: true,
  },
  {
    id: 2,
    url: './assets/image/2.jpg',
    title: 'Image 2',
    description: 'Image 2 description',
    tintIntensity: 75,
    glore: true,
    category: {
      background: "Ganage1",
      window: "0.3",
      numberPlate: "White Mask"
    },
  },
  {
    id: 3,
    url: './assets/image/3.jpg',
    title: 'Image 3',
    description: 'Image 3 description',
    tintIntensity: 75,
    glore: true,
    category: {
      background: "Ganage1",
      window: "0.3",
      numberPlate: "White Mask"
    },
  },
  {
    id: 4,
    url: './assets/image/4.jpg',
    title: 'Image 4',
    description: 'Image 4 description',
    tintIntensity: 75,
    glore: true,
    category: {
      background: "Ganage1",
      window: "0.3",
      numberPlate: "White Mask"
    },
  },
];

export const BGs = [
  {
    "name": "345245",
    "id": "345245",
    "thumbnail": "./assets/image/bgs/bg_345245.png"
  },
  // {
  //   "name": "Studio BG",
  //   "id": "288837",
  //   "thumbnail": "https://s3.ap-south-1.amazonaws.com/carromm.com/assets/288837.png"
  // },
  // {
  //   "name": "Garage2",
  //   "id": "401",
  //   "thumbnail": "https://s3.ap-south-1.amazonaws.com/carromm.com/assets/401.png"
  // },
  // {
  //   "name": "Garage1",
  //   "id": "402",
  //   "thumbnail": "https://s3.ap-south-1.amazonaws.com/carromm.com/assets/404.png"
  // },
  // {
  //   "name": "Garage3",
  //   "id": "sha_412",
  //   // "thumbnail": "https://carromm.s3.amazonaws.com/Zulfi/replacebg/car_replace_bg_e30e7605-820c-4e35-832f-49ce98ed8ac7.png"
  //   "thumbnail": "./assets/image/bgs/bg_sha_412.png"
  // },
  // {
  //   "name": "13488",
  //   "id": "13488",
  //   "thumbnail": "./assets/image/bgs/bg_13488.png"
  // },
  // {
  //   "name": "77663",
  //   "id": "77663",
  //   "thumbnail": "./assets/image/bgs/bg_77663.png"
  // },
  {
    "name": "288837",
    "id": "288837",
    "thumbnail": "./assets/image/bgs/bg_288837.png"
  },
  {
    "name": "345213",
    "id": "345213",
    "thumbnail": "./assets/image/bgs/bg_345213.png"
  },
  {
    "name": "345214",
    "id": "345214",
    "thumbnail": "./assets/image/bgs/bg_345214.png"
  },
  {
    "name": "345215",
    "id": "345215",
    "thumbnail": "./assets/image/bgs/bg_345215.png"
  },
  {
    "name": "345216",
    "id": "345216",
    "thumbnail": "./assets/image/bgs/bg_345216.png"
  },
  {
    "name": "345217",
    "id": "345217",
    "thumbnail": "./assets/image/bgs/bg_345217.png"
  },
  // {
  //   "name": "345218",
  //   "id": "345218",
  //   "thumbnail": "./assets/image/bgs/bg_345218.png"
  // },
  {
    "name": "345219",
    "id": "345219",
    "thumbnail": "./assets/image/bgs/bg_345219.png"
  },
  // {
  //   "name": "345220",
  //   "id": "345220",
  //   "thumbnail": "./assets/image/bgs/bg_345220.png"
  // },
  {
    "name": "345237",
    "id": "345237",
    "thumbnail": "./assets/image/bgs/bg_345237.jpg"
  },
  {
    "name": "345239",
    "id": "345239",
    "thumbnail": "./assets/image/bgs/bg_345239.jpg"
  },

  {
    "name": "80655",
    "id": "80655",
    "thumbnail": "./assets/image/bgs/bg_80655.png"
  },

  // {
  //   "name": "new_bg_001",
  //   "id": "new_bg_001",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_001.png"
  // },
  // {
  //   "name": "new_bg_002",
  //   "id": "new_bg_002",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_002.png"
  // },
  // {
  //   "name": "new_bg_003",
  //   "id": "new_bg_003",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_003.png"
  // },
  // {
  //   "name": "new_bg_005",
  //   "id": "new_bg_005",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_005.png"
  // },

  {
    "name": "new_bg_007",
    "id": "new_bg_007",
    "thumbnail": "./assets/image/bgs/bg_new_bg_007.png"
  },
  // {
  //   "name": "new_bg_008",
  //   "id": "new_bg_008",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_008.png"
  // },
  // {
  //   "name": "new_bg_009",
  //   "id": "new_bg_009",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_009.png"
  // },
  // {
  //   "name": "new_bg_010",
  //   "id": "new_bg_010",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_010.png"
  // },
  // {
  //   "name": "new_bg_011",
  //   "id": "new_bg_011",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_011.png"
  // },
  // {
  //   "name": "new_bg_012",
  //   "id": "new_bg_012",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_012.png"
  // },
  // {
  //   "name": "new_bg_013",
  //   "id": "new_bg_013",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_013.png"
  // },
  // {
  //   "name": "new_bg_014",
  //   "id": "new_bg_014",
  //   "thumbnail": "./assets/image/bgs/bg_new_bg_014.png"
  // },
  {
    "name": "345235_PX0021",
    "id": "345235_PX0021",
    "thumbnail": "./assets/image/bgs/bg_345235_PX0021.png"
  }
]

export const rawDataNumberPlates = [
  {
    "name": "No Mask",
    "id": "0",
    "thumbnail": ""
  },
  {
    "name": "White Mask",
    "id": "123",
    "thumbnail": "https://carromm.s3.amazonaws.com/raw/number_plate.png"
  },
  {
    "name": "Pic Designer",
    "id": "1232",
    "thumbnail": PUBLIC_URL + "/assets/image/PIC-DESIGNER-DEMO.png"
  },
];