import React from 'react'
import { useSelector } from 'react-redux';

const SelectedCarBlock = ({ handleNext, target }) => {
  const { bg_id, window_refinement, number_plate, previewImages } = useSelector(state => state.carromm);

  return (
    <div
      key={previewImages || 'no-image'}
      className="selected-car-block"
    >
      <div className="car-box-wrapper">
        <div className="car-box">
          <img
            src={previewImages?.[0]?.url}
            alt={previewImages?.[0]?.url} />
        </div>
      </div>
      <div className="car-category-info">
        <h3 className="title-20">Kategorie</h3>
        <ul>
          <li>
            <span>Hintergrund</span>: {bg_id}
          </li>
          <li>
            <span>Fenster</span>: {window_refinement?.TRANSPARENCY || 0.45}
          </li>
          <li>
            <span>Nummernschild</span>: {number_plate?.name}
          </li>
        </ul>
      </div>
      {
        target === 'background' ? bg_id && <div className="process-btn-wrap">
          <button
            className="btn btn-process"
            onClick={handleNext}
          >
            Weiter
          </button>
        </div> :
          <div className="process-btn-wrap">
            <button
              className="btn btn-process"
              onClick={handleNext}
            >
              Weiter
            </button>
          </div>
      }

    </div>
  )
}

export default SelectedCarBlock