import React, { useState } from 'react'
import { BGs } from '../../constants/rowData.constants'
import SelectedCarBlock from '../../components/selectedCarBlock';
import { useSelector, useDispatch } from 'react-redux';
import { setBG } from '../../redux/slices/carrommSlice';
import { useCarromm } from '../../context/carromm';
import Carousel, { consts } from 'react-elastic-carousel';
import UpArrow from '../../components/icons/UpArrow';
import DownArrow from '../../components/icons/DownArrow';

const Backgrounds = () => {
  const { bg_id } = useSelector(state => state.carromm);

  const [backgroundImages, setBackgroundImages] = useState(BGs);
  const carrom = useCarromm();
  const dispatch = useDispatch();

  const handleSelectBackground = (item) => {
    setBackgroundImages(backgroundImages.map((t) => ({ ...t, isSelected: t.id === item.id })));
    dispatch(setBG(item.id));
  }
  const handleNext = () => {
    carrom.setTabs(carrom.tabs.map((t) => ({ ...t, active: t.id === 'windows' })));
  };


  const arrowButtons = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <UpArrow/> : <DownArrow/>
    return (
      <button onClick={onClick} disabled={isEdge} className='arrow-btn'>
        {pointer}
      </button>
    )
  }

  return (
    <>
      <h2 className="upload-modal-title">picDesigner Demo</h2>
      <p className="upload-modal-description">Professionelle Aufnahmen mit wenigen Klicks</p>
      <div className="select-background-block">
        <div className="bg-list" >
          <div>
          </div>
          <ul id='scroll'>
            <Carousel verticalMode itemsToShow={4} renderArrow={arrowButtons}>
              {backgroundImages &&
                Array.isArray(backgroundImages) &&
                backgroundImages?.map((item, index) => (
                  <li
                    key={item?.id || index}
                    className={item.isSelected || item.id === bg_id ? "selected-bg" : ''}
                    onClick={() => handleSelectBackground(item)}>
                    <img src={item?.thumbnail} alt={item?.thumbnail} />
                  </li>
                ))}
            </Carousel>
          </ul>
          <div>
          </div>
        </div>
        <SelectedCarBlock
          handleNext={handleNext} target="background" />
      </div>
    </>
  )
}

export default Backgrounds