import React from 'react'
import SelectedCarBlock from '../../components/selectedCarBlock';
import { useSelector, useDispatch } from 'react-redux';
import { setWindowRefinementTransparency, toggleGlare } from '../../redux/slices/carrommSlice';
import { useCarromm } from '../../context/carromm';

const Windows = () => {
  const { window_refinement } = useSelector(state => state.carromm);
  const dispatch = useDispatch();
  const carrom = useCarromm();
  const handleNext = () => {
    carrom.setTabs(carrom.tabs.map((t) => ({ ...t, active: t.id === 'number-plate' })));
  };
  return (
    <>
      <h2 className="upload-modal-title">picDesigner Demo</h2>
      <p className="upload-modal-description">Professionelle Aufnahmen mit wenigen Klicks</p>
      <div className="select-background-block">
        <div className="bg-list">
          <h3 className="title-18">Intensität der Tönung</h3>
          <div className="select-range-slider">
            <div className="mb-3">
              <input
                type="range"
                className="w-100"
                min="0"
                max="1"
                step='0.01'
                value={window_refinement?.TRANSPARENCY || 0.45}
                onChange={e => dispatch(setWindowRefinementTransparency(e.target.value))} />
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id='tint-intensity'
                name="option1"
                value={window_refinement.ADD_GLARE}
                onChange={e => dispatch(toggleGlare(e.target.checked ? true : false))}
                checked={window_refinement.ADD_GLARE} />
              <label className="form-check-label">Blendung hinzufügen</label>
            </div>
          </div>
        </div>
        <SelectedCarBlock
          handleNext={handleNext} />
      </div>
    </>

  )
}

export default Windows