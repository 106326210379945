export const APP_ROOT = process.env.REACT_APP_BASE_URL;
export const APP_ADMIN_SLUG = process.env.REACT_APP_ADMIN_SLUG;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const CLOUD_URL = process.env.REACT_APP_CLOUD_URL || "http://localhost:4001";
export const BASE_URL = process.env.baseURL;
export const ASSETS_URL = BASE_URL + '/assets/';

export const navTabs = [
  {
    id: "home",
    name: "Home",
    title: "Startseite",
    icon: "./assets/image/icon/home.svg",
    link: "/home",
    active: true,
  },
  {
    id: "backgrounds",
    name: "Backgrounds",
    title: "Hintergründe",
    icon: "./assets/image/icon/image-background.svg",
    link: "/backgrounds",
    active: false,
  },
  {
    id: "windows",
    name: "Windows",
    title: "Fenster",
    icon: "./assets/image/icon/car-door.svg",
    link: "/windows",
    active: false,
  },
  {
    id: "number-plate",
    name: "NumberPlate",
    title: "Nummernschild",
    icon: "./assets/image/icon/number-plate.svg",
    link: "/number-plate",
    active: false,
  },
  {
    id: "show-result",
    name: "Result",
    title: "Ergebnis",
    // icon: ,
    link: "/show-result",
    active: false,
  }
];

export const defaultValues = {
  bg_id: "sha_412",
  window_refinement: { 'TRANSPARENCY': 0.45, 'TINT_COLOR': (166, 205, 203), 'TONE_ON_TONE': false, 'ADD_GLARE': true },
  interior_regeneration: 1,
  number_plate: "https://carromm.s3.amazonaws.com/raw/number_plate.png",
  extension: "",
};