import { createContext, useContext, useEffect, useState } from "react";
import { navTabs } from "../constants/common.constants";

const CarrommContext = createContext(null);

export const useCarromm = () => useContext(CarrommContext);

export const CarrommProvider = ({ children }) => {
  const [input, setInput] = useState(null);
  const [tabs, setTabs] = useState(navTabs || []);

  return (
    <CarrommContext.Provider
      value={{
        input,
        setInput,
        tabs,
        setTabs
      }}>
      {children}
    </CarrommContext.Provider>
  );
}