import { useSelector } from 'react-redux';
import React, { useRef } from 'react'
import UploadInput from '../formComponents/uploadInput'
import { useCarromm } from '../../context/carromm';

const UploadModal = ({ handleClick }) => {
  const loading = useSelector(state => state.carromm.loading);
  const ref = useRef();
  const carrom = useCarromm();

  return (
    <div className="upload-modal">
      <div className="upload-modal-body">
        <UploadInput ref={ref} />
      </div>
      <div className="upload-modal-footer">
        <button className="btn-secondary">Abbrechen</button>
        <button
          className="btn-primary"
          onClick={handleClick}
          disabled={!carrom.input}>
          Weiter
        </button>
      </div>
    </div>
  )
}

export default UploadModal